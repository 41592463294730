<template>
	<div>

		<!-- 凭证界面 -->
		<el-dialog title="凭证列表" top="1vh" width="90%" :visible.sync="is_show_in_Page">
			<div class="voucher">
				<div class="list" v-if="list.length>0">
					<div class="item" v-for="(voucher_item,index) in list" :key="index">
						<div class="del_btn" v-if="voucher_item.could_del" @click="voucher_del(index)">×</div>
						<img class="img" :src="voucher_item.src">
						<div class="text">{{voucher_item.mark}}</div>
					</div>
				</div>
				<div class="list_none" v-else>
					无数据
				</div>
			</div>
		</el-dialog>

	</div>
</template>
<script>
	export default {
		props:{
			is_show:Number,//是否显示
			voucher_info:Array,//凭证列表
		},
		data() {
			return {

				//列表是否显示
				is_show_in_Page:false,

				//凭证列表
				list:[],
			}
		},
		mounted() {
			
		},
		watch:{
			voucher_info(new_data){
				if(new_data&&this.$my.check.is_arr(new_data)){

					//构造数据
					let list=[];
					for(var item of new_data){

						//上传人
						switch(item.upl_user_type){
							case '1':var upl_type_text="货主上传";break;
							case '2':var upl_type_text="司机上传";break;
							case '3':var upl_type_text="后台上传";break;
							case '4':var upl_type_text="货主子账号上传";break;
						}

						list.push({
							voucher_id:item.id,
							could_del:false,
							src:this.$my.qiniu.make_src('trucktordrealtimevoucher', item.img_key),
							mark:item.mark?upl_type_text+"(备注:"+item.mark+")":upl_type_text
						})
					}

					this.list=list
				}
			},
			is_show(new_data){
				if(new_data){
					this.is_show_in_Page=true;
				}
			},
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>

	.voucher{
		margin-top: -10px;
		.list_none{
			height:300px;
			line-height:300px;
			text-align:center;
			font-size: 18px;
		}
		.list{
			display: flex;
			flex-wrap: wrap;
			padding-bottom: 30px;
			.item{
				margin: 15px 0.4% 0 0.4%;
				width: 19%;
				height: 19vw;
				position: relative;
				border: 1px solid #ccc;
				border-radius: 5px;
				.del_btn{
					font-size: 20px;
					width: 40px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					border-radius: 20px;
					color: #fff;
					background-color: #000;
					opacity: 0.5;
					position: absolute;
					right: -10px;
					top: -10px;
					cursor: pointer;
				}
				.img{
					max-width: 95%;
					max-height: calc( 100% - 20px - 1vw);
					display: block;
					margin: 0.3vw auto 0 auto;
				}
				.text{
					position: absolute;
					bottom: 0;
					width: 100%;
					height: 40px;
					line-height: 40px;
					text-align: center;
				}
			}
		}
	}
</style>