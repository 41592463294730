 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="组别编号">
				<el-input class="el_input" v-model="form.group1_num" placeholder="运单组别编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人手机">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="收款人手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人姓名">
				<el-input class="el_input" v-model="form.truck_owner_name" placeholder="收款人姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机姓名">
				<el-input class="el_input" v-model="form.driver_name" placeholder="司机姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="结束类型">
				<el-select class="el_input" v-model="form.payed_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="完结运单" value="1"></el-option>
					<el-option label="取消运单" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="运费状态">
				<el-select class="el_input" v-model="form.freight_payed_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未支付" value="1"></el-option>
					<el-option label="部分支付" value="2"></el-option>
					<el-option label="支付完毕" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="服务费状态">
				<el-select class="el_input" v-model="form.service_charge_payed_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未支付" value="1"></el-option>
					<el-option label="部分支付" value="2"></el-option>
					<el-option label="支付完毕" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="发票状态">
				<el-select class="el_input" v-model="form.invoice_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未申请" value="1"></el-option>
					<el-option label="已申请" value="2"></el-option>
					<el-option label="已开票" value="3"></el-option>
					<el-option label="被驳回" value="4"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
			</el-form-item>
		</el-form>
		

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="运单编号/组别编号/上游单号" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item">编号:{{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">组别:{{scope.row.group1_num?scope.row.group1_num:"无"}}</div>
						<div class="tab_line_item">上游:{{scope.row.out_trade_num?scope.row.out_trade_num:"无"}}</div>
						<div class="tab_line_item">结束:{{scope.row.tord_end_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cargo_info.case_prov}}/{{scope.row.cargo_info.case_city}}/{{scope.row.cargo_info.case_county}}</div>
						<div class="tab_line_item">{{scope.row.cargo_info.case_other.addr_info?scope.row.cargo_info.case_other.addr_info:'无详细地址'}}</div>
						<div class="tab_line_item">联系人:{{scope.row.cargo_info.case_link_man_text}}/{{scope.row.cargo_info.case_link_tel_text}}</div>
						<div class="tab_line_item">发车时间:{{scope.row.cargo_info.case_other.start_time}}{{(scope.row.cargo_info.case_other.start_time_is_check_text)}}</div>
					</template>
				</el-table-column>
				<el-table-column label="卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cargo_info.aim_prov}}/{{scope.row.cargo_info.aim_city}}/{{scope.row.cargo_info.aim_county}}</div>
						<div class="tab_line_item">{{scope.row.cargo_info.aim_other.addr_info?scope.row.cargo_info.aim_other.addr_info:'无详细地址'}}</div>
						<div class="tab_line_item">联系人:{{scope.row.cargo_info.aim_link_man_text}}/{{scope.row.cargo_info.aim_link_tel_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="名称/重量/体积" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cargo_info.cargo_type}}/{{scope.row.cargo_info.cargo_name}}</div>
						<div class="tab_line_item">{{scope.row.cargo_info.cargo_weight==0?"无重量":scope.row.cargo_info.cargo_weight+"吨"}}/{{scope.row.cargo_info.cargo_volume==0?"无体积":scope.row.cargo_info.cargo_volume+"方"}}</div>
						<div class="tab_line_item">距离:{{scope.row.cargo_info.distance}}KM</div>
					</template>
				</el-table-column>
				<el-table-column label="运费/支付状态" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cost_info.freight_total}}元</div>
						<div class="tab_line_item">{{scope.row.settlement_type_text}}/{{scope.row.is_need_return_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="收款人/司机/货车信息" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item">收款人:{{scope.row.truck_owner_info.name}}/{{scope.row.truck_owner_info.tel}}</div>
						<div class="tab_line_item">司机:{{scope.row.driver_info.name}}/{{scope.row.driver_info.tel}}</div>
						<div class="tab_line_item">{{scope.row.truck_info.truck_plate_num}}/{{scope.row.truck_info.truck_type_name_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作网点" width="140px">
					<template slot-scope="scope">
						<div class="tab_line_item">姓名:{{scope.row.outlets_info.name?scope.row.outlets_info.name:'无'}}</div>
						<div class="tab_line_item">电话:{{scope.row.outlets_info.tel?scope.row.outlets_info.tel:'无'}}</div>
						<div class="tab_line_item">网点:{{scope.row.outlets_info.outlets_name?scope.row.outlets_info.outlets_name:'无'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单状态/时间信息" width="172px">
					<template slot-scope="scope">
						<div class="tab_line_item">状态:{{scope.row.tord_status_text}}</div>
						<div class="tab_line_item">添加:{{scope.row.tord_creat_time_text}}</div>
						<div class="tab_line_item">发车:{{scope.row.tord_start_time_text}}</div>
						<div class="tab_line_item">到货:{{scope.row.tord_arrived_time_text}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="75">
					<template slot-scope="scope">
						<el-button @click="republish(scope.row)" size="mini" type="text">重新发布</el-button>
						<el-button @click="voucher_show(scope.row)" size="mini" type="text" class="btn_left">凭证列表</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>

		<!-- 凭证界面 -->
		<voucher 
			:is_show="voucher.is_show"
			:voucher_info="voucher.voucher_info"
		>
		</voucher>

	</div>
</template>

<script>
	import voucher from '../voucher/voucher_after_end.vue'
	export default {
		components:{
			voucher
		},
		data() {
			return {

				//搜索条件
				form: {
					truck_tord_num:'',//货源编号
					group1_num:'',//组编号1(用户自定义打组)
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					type:'',//类型(1:完结运单,2:取消运单)
					invoice_status:'',//开票状态(1:未申请,2:已申请,3:已开出,4:被驳回)
					freight_payed_status:'',//运费支付状态(1:未支付,2:部分支付,3:已支付)
					service_charge_payed_status:'',//服务费支付状态(1:未支付,2:部分支付,3:已支付)
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				
				//凭证相关
				voucher:{
					is_show:0,
					truck_tord_num:'',
					voucher_info:[],
				},
			}
		},
		created() {

			//读取货源
			this.get_page_data()
		},
		methods: {
			
			//凭证相关操作
			voucher_show(item){//显示凭证
				this.voucher.is_show++;
				this.voucher.voucher_info=item.voucher_info
			},

			//前往运单详情
			republish(item){

				//缓存订单数据
				this.$my.cache.set({
					__cargo_creat_buf:{
						bl_num:item.bl_num,
						group1_num:item.group1_num,
						settlement_type:item.settlement_type,
						is_need_return:item.is_need_return,
						cargo_name:item.cargo_info.cargo_name,
						cargo_type:item.cargo_info.cargo_type,
						cargo_weight:item.cargo_info.cargo_weight,
						cargo_volume:item.cargo_info.cargo_volume,
						need_truck_type:item.cargo_info.need_truck_type,
						need_truck_type2:item.cargo_info.need_truck_type2,
						case_prov:item.cargo_info.case_prov,
						case_city:item.cargo_info.case_city,
						case_county:item.cargo_info.case_county,
						case_link_man:item.cargo_info.case_link_man,
						case_link_tel:item.cargo_info.case_link_tel,
						case_lat:item.cargo_info.case_lat,
						case_lng:item.cargo_info.case_lng,
						case_other:item.cargo_info.case_other,
						aim_prov:item.cargo_info.aim_prov,
						aim_city:item.cargo_info.aim_city,
						aim_county:item.cargo_info.aim_county,
						aim_link_man:item.cargo_info.aim_link_man,
						aim_link_tel:item.cargo_info.aim_link_tel,
						aim_lat:item.cargo_info.aim_lat,
						aim_lng:item.cargo_info.aim_lng,
						aim_other:item.cargo_info.aim_other,
						distance:item.cargo_info.distance,
						freight_total:item.cost_info.freight_total,
						mark:item.cargo_info.mark,
						truck_info:{
							tel:item.truck_owner_info.tel,
							name:item.truck_owner_info.name,
							user_num:item.truck_owner_num,
						},
					}
				})

				//跳转
				this.$router.push({
					path:'/pages/tord_real_time/cargo_creat',
					query:{
						creat_type:'直接发货'
					}
				});
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					truck_tord_num:'',//货源编号
					group1_num:'',//组编号1(用户自定义打组)
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					type:'',//类型(1:完结运单,2:取消运单)
					invoice_status:'',//开票状态(1:未申请,2:已申请,3:已开出,4:被驳回)
					freight_payed_status:'',//运费支付状态(1:未支付,2:部分支付,3:已支付)
					service_charge_payed_status:'',//服务费支付状态(1:未支付,2:部分支付,3:已支付)
				}
				this.get_page_data()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_end_list_by_shipper',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//运单创建时间
							item.tord_creat_time_text=this.$my.other.totime(item.tord_creat_time);
							
							//运单发车时间
							if(item.tord_start_time>0){//已发车
								item.tord_start_time_text=this.$my.other.totime(item.tord_start_time);
							}else item.tord_start_time_text="未发车";
							
							//运单到达时间
							if(item.tord_arrived_time>0){//已到货
								item.tord_arrived_time_text=this.$my.other.totime(item.tord_arrived_time);
							}else item.tord_arrived_time_text="未到货";

							//运单结束时间
							item.tord_end_time_text=this.$my.other.totime(item.tord_end_time);

							//运单状态
							switch(item.tord_status){
								case '1':item.tord_status_text="待发车";break;
								case '2':item.tord_status_text="运输中";break;
								case '3':item.tord_status_text="已到货";break;
							}

							//结算方式
							switch(item.settlement_type){
								case '1':item.settlement_type_text="按吨数";break;
								case '2':item.settlement_type_text="按方数";break;
								case '3':item.settlement_type_text="按趟";break;
							}
							
							//是否回程
							switch(item.is_need_return){
								case '1':item.is_need_return_text="需要回程";break;
								case '2':item.is_need_return_text="不需要回程";break;
							}

							//运费支付状态
							switch(item.freight_payed_status){
								case '1':item.freight_payed_status_text="未支付";break;
								case '2':item.freight_payed_status_text="部分支付";break;
								case '3':item.freight_payed_status_text="支付完毕";break;
								default :item.freight_payed_status_text="未支付";break;
							}

							//货源详情
							item.cargo_info=JSON.parse(item.cargo_info)

							//装货联系人
							if(item.cargo_info.case_link_man){
								item.cargo_info.case_link_man_text=item.cargo_info.case_link_man;
							}else item.cargo_info.case_link_man_text="无";

							//装货联系电话
							if(item.cargo_info.case_link_tel){
								item.cargo_info.case_link_tel_text=item.cargo_info.case_link_tel;
							}else item.cargo_info.case_link_tel_text="无";

							//卸货联系人
							if(item.cargo_info.aim_link_man){
								item.cargo_info.aim_link_man_text=item.cargo_info.aim_link_man;
							}else item.cargo_info.aim_link_man_text="无";

							//卸货联系电话
							if(item.cargo_info.aim_link_tel){
								item.cargo_info.aim_link_tel_text=item.cargo_info.aim_link_tel;
							}else item.cargo_info.aim_link_tel_text="无";

							//发车时间
							if(!item.cargo_info.case_other.start_time_1 && item.cargo_info.case_other.start_time_2){//只有止点

								item.cargo_info.case_other.start_time=this.$my.other.todate(item.cargo_info.case_other.start_time_2)+"以前";

							}else if(item.cargo_info.case_other.start_time_1 && !item.cargo_info.case_other.start_time_2){//只有起点

								item.cargo_info.case_other.start_time=this.$my.other.todate(item.cargo_info.case_other.start_time_1)+"以后";

							}else if(item.cargo_info.case_other.start_time_1 && item.cargo_info.case_other.start_time_2){//都有

								item.cargo_info.case_other.start_time=this.$my.other.todate(item.cargo_info.case_other.start_time_1)+" ~ "+this.$my.other.todate(item.cargo_info.case_other.start_time_2);

							}else if(!item.cargo_info.case_other.start_time_1 && !item.cargo_info.case_other.start_time_2){//都没有
								
								item.cargo_info.case_other.start_time="未设置";
							}

							//发车时间是否强制校验
							switch(item.cargo_info.case_other.start_time_is_check){
								case '1':item.cargo_info.case_other.start_time_is_check_text="(强制)";break;
								case '2':item.cargo_info.case_other.start_time_is_check_text="(不强制)";break;
							}
							if(item.cargo_info.case_other.start_time=="未设置"){//如果未设置发车时间,则不存在强制不强制
								item.cargo_info.case_other.start_time_is_check_text=""
							}

							//费用信息
							item.cost_info=JSON.parse(item.cost_info)

							//货主详情
							item.shipper_info=JSON.parse(item.shipper_info)

							//货主公司详情
							item.shipper_company_info=JSON.parse(item.shipper_company_info)

							//网点信息
							item.outlets_info=JSON.parse(item.outlets_info)

							//网点用户信息
							item.outlets_user_info=JSON.parse(item.outlets_user_info)

							//收款人详情
							item.truck_owner_info=JSON.parse(item.truck_owner_info)

							//司机数据
							item.driver_info=JSON.parse(item.driver_info)

							//货车详情
							item.truck_info=JSON.parse(item.truck_info)

							//货车类型
							item.truck_info.truck_type_name_text=item.truck_info.truck_type_name+'/'+item.truck_info.truck_type_name2

							//凭证列表
							item.voucher_info=JSON.parse(item.voucher_info)
						}

						//渲染
						this.list.data=data.list
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>